import * as Sentry from '@sentry/react';
// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from '@store/storeConfig/store';

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './components/spinner/Fallback-spinner';

// ** Ripple Button
import './components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import 'bs-stepper/dist/css/bs-stepper.min.css';
import '@styles/react/libs/flatpickr/flatpickr.scss';
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './@core/scss/base/plugins/forms/form-wizard.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <ThemeContext>
                <LazyApp />
                <ToastContainer newestOnTop />
            </ThemeContext>
        </Suspense>
    </Provider>,
    document.getElementById('root'),
);

// we should change REACT_APP_VERSION for evry release, to make sentry track for every new version
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_VERSION || 'dev',
    enabled: process.env.NODE_ENV !== 'development',
    tracesSampleRate: 1.0,
    tracePropagationTargets: [process.env.REACT_APP_SENTRY_API_TARGET],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// "start": "set HTTPS=true&&craco start",

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
