const actions = {
    LIST_ACTIVITY: 'LIST_ACTIVITY',
    LIST_TYPE: 'LIST_TYPE',
    LIST_GENRE: 'LIST_GENRE',
    LIST_MISSION: 'LIST_MISSION',
    LIST_PARTICIPANT: 'LIST_PARTICIPANT',
    LIST_EXPORT: 'LIST_EXPORT',
    LIST_TEMPLATES: 'LIST_TEMPLATES',
    LIST_RESPONSES: 'LIST_RESPONSES',
    POST_TEMPLATES: 'POST_TEMPLATES',

    postTemplates: (data) => {
        return {
            type: actions.POST_TEMPLATES,
            data,
        };
    },

    getResponses: (data) => {
        return {
            type: actions.LIST_RESPONSES,
            data,
        };
    },

    getTemplates: (data) => {
        return {
            type: actions.LIST_TEMPLATES,
            data,
        };
    },

    getExport: (data) => {
        return {
            type: actions.LIST_EXPORT,
            data,
        };
    },

    getParticipant: (data) => {
        return {
            type: actions.LIST_PARTICIPANT,
            data,
        };
    },

    getMission: (data) => {
        return {
            type: actions.LIST_MISSION,
            data,
        };
    },

    getGenre: (data) => {
        return {
            type: actions.LIST_GENRE,
            data,
        };
    },

    getType: (data) => {
        return {
            type: actions.LIST_TYPE,
            data,
        };
    },

    getActivity: (data) => {
        return {
            type: actions.LIST_ACTIVITY,
            data,
        };
    },
};

export default actions;
