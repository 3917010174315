import actions from './actions';

const {
    LIST_CONSULTINGS,
    LIST_PLATEFORM_SECURSAL,
    LIST_CLIENTS,
    LIST_QUALIFICATIONS,
    ALL_CLIENT,
    POST_CLIENT,
    LIST_IMAGES_BY_SECTOR,
    MISSION_ADDED,
    MISSION_MODIFIED,
} = actions;

const initialState = {
    consultings: null,
    plateformSecursal: null,
    clients: null,
    qualifications: null,
    allCLient: null,
    postClient: null,
    imagesSectors: null,
    missionAdded: null,
    missionModified: null,
};

const adsReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case MISSION_ADDED:
            return { ...state, missionAdded: data };
        case MISSION_MODIFIED:
            return { ...state, missionModified: data };
        case POST_CLIENT:
            return { ...state, postClient: data };
        case ALL_CLIENT:
            return { ...state, allCLient: data };
        case LIST_CONSULTINGS:
            return { ...state, consultings: data };
        case LIST_PLATEFORM_SECURSAL:
            return { ...state, plateformSecursal: data };
        case LIST_CLIENTS:
            return { ...state, clients: data };
        case LIST_QUALIFICATIONS:
            return { ...state, qualifications: data };
        case LIST_IMAGES_BY_SECTOR:
            return { ...state, imagesSectors: data };
        default:
            return state;
    }
};

export default adsReducer;
