const actionsAlert = {
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_ERROR: 'ALERT_ERROR',
    ALERT_HIDE: 'ALERT_HIDE',
    ALERT_CONFIRM: 'ALERT_CONFIRM',

    /* POPUP */
    alertPopup: (data) => {
        return {
            type: actionsAlert.ALERT_CONFIRM,
            data: data,
        };
    },

    /* success alert */
    alert: (data) => {
        return {
            type: actionsAlert.ALERT_SUCCESS,
            data: data,
        };
    },

    /* warning alert */
    alert: (data) => {
        return {
            type: actionsAlert.ALERT_WARNING,
            data: data,
        };
    },

    /* error alert */
    alertError: (data) => {
        return {
            type: actionsAlert.ALERT_ERROR,
            data: data,
        };
    },

    /* hide alert */
    alertHide: (data) => {
        return {
            type: actionsAlert.ALERT_HIDE,
            data: data,
        };
    },
};

export default actionsAlert;
