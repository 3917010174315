import securityModalActions from './actions';

const { DISPLAY_MODAL, HIDE_MODAL } = securityModalActions;

const initialState = {
    status: '',
    statusCode: null,
    modalType: '',
    modalTitle: '',
    userEmail: '',
};

const securityModalReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case DISPLAY_MODAL:
            return {
                ...state,
                status: data.status,
                statusCode: data.status_code,
                modalType: data.modalType,
                modalTitle: data.modalTitle,
                userEmail: data.userEmail,
            };
        case HIDE_MODAL:
            return {
                ...state,
                modalType: '',
                statusCode: null,
                status: '',
                modalTitle: '',
                userEmail: '',
            };
        default:
            return state;
    }
};

export default securityModalReducer;
