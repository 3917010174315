import announcementModalActions from './actions';

const { Modal_SUCCESS, Modal_ERROR, Modal_HIDE, Modal_WARNING, Modal_CONFIRM } = announcementModalActions;

const initialState = {
    modalType: '',
    announcementStatus: '',
    announcementTitle: '',
    announcementId: '',
    source: '',
    dataConnexion: {},
};

const announcementModalReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case Modal_SUCCESS:
            return {
                ...state,
                modalType: data.modalType,
                announcementTitle: data.announcementTitle,
                announcementStatus: data.announcementStatus,
                announcementId: data.announcementId,
                source: data.source,
            };
        case Modal_WARNING:
            return {
                ...state,
                modalType: data.modalType,
                announcementTitle: data.announcementTitle,
                announcementStatus: data.announcementStatus,
            };
        case Modal_ERROR:
            return {
                ...state,
                modalType: data.modalType,
                announcementStatus: data.announcementStatus,
            };
        case Modal_HIDE:
            return {
                ...state,
                modalType: '',
                announcementTitle: '',
                announcementStatus: '',
            };
        default:
            return state;
    }
};

export default announcementModalReducer;
