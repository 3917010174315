const announcementModalActions = {
    Modal_SUCCESS: 'Modal_SUCCESS',
    Modal_WARNING: 'Modal_WARNING',
    Modal_ERROR: 'Modal_ERROR',
    Modal_HIDE: 'Modal_HIDE',
    Modal_CONFIRM: 'Modal_CONFIRM',

    confirmModal: (data) => {
        return {
            type: announcementModalActions.Modal_CONFIRM,
            data: data,
        };
    },

    successModal: (data) => {
        return {
            type: announcementModalActions.Modal_SUCCESS,
            data: data,
        };
    },

    warningModal: (data) => {
        return {
            type: announcementModalActions.Modal_WARNING,
            data: data,
        };
    },

    errorModal: (data) => {
        return {
            type: announcementModalActions.Modal_ERROR,
            data: data,
        };
    },

    hideModal: (data) => {
        return {
            type: announcementModalActions.Modal_HIDE,
            data: data,
        };
    },
};

export default announcementModalActions;
