const actions = {
    IS_PENDING: 'IS_PENDING',
    IS_ERROR: 'IS_ERROR',
    IS_SUCCESS: 'IS_SUCCESS',

    isPendingStatus: (isPending) => {
        return {
            type: actions.IS_PENDING,
            data: isPending,
        };
    },

    isErrorStatus: (isError) => {
        return {
            type: actions.IS_ERROR,
            data: isError,
        };
    },

    isSuccessStatus: (isSuccess) => {
        return {
            type: actions.IS_ERROR,
            data: isSuccess,
        };
    },
};

export default actions;
