import actions from './actions';

const {
    GET_USER_DETAILS,
    LIST_MULTIPOSTING_USER,
    EDIT_USER_DETAILS,
    UPLOAD_PROFILE_IMAGE,
    ADD_FORMATION,
    DELETE_FORMATION,
    ADD_EXP,
    DELETE_EXP,
    EDIT_FORMATION,
    DELETE_FILE,
    DELETE_FILES,
    EDIT_EXP,
    GET_LANG_LEVELS,
    GET_EDU_LEVEL,
    GET_EDUC_LEVEL,
    GET_PRE_DEF_LANG,
    GET_FILTERED_LANGS,
    GET_JOB_TYPES,
    CHECK_CANDIDATE_HAD_CANDIDATURES,
} = actions;

const initialState = {
    user: null,
    multiposting: null,
    editUser: false,
    profileImageChanged: false,
    formationAdded: false,
    deleteFormation: false,
    expAdded: false,
    deleteExp: false,
    deleteFile: false,
    deleteFiles: false,
    editFormation: false,
    editExp: false,
    languageLevels: [],
    educationLevels: [],
    educationLevel: [],
    langs: [],
    jobTypes: [],
    isCandidateHadCandidatures: false,
};

const ProfileReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_USER_DETAILS:
            return { ...state, user: data };
        case LIST_MULTIPOSTING_USER:
            return { ...state, multiposting: data };
        case EDIT_USER_DETAILS:
            return { ...state, editUser: true };
        case UPLOAD_PROFILE_IMAGE:
            return { ...state, profileImageChanged: true };
        case ADD_FORMATION:
            return { ...state, formationAdded: true };
        case DELETE_FORMATION:
            return { ...state, deleteFormation: true };
        case ADD_EXP:
            return { ...state, expAdded: true };
        case DELETE_EXP:
            return { ...state, deleteExp: true };
        case DELETE_FILE:
            return { ...state, deleteFile: true };
        case DELETE_FILES:
            return { ...state, deleteFiles: true };
        case EDIT_FORMATION:
            return { ...state, editFormation: true };
        case EDIT_EXP:
            return { ...state, editExp: true };
        case GET_LANG_LEVELS:
            return { ...state, languageLevels: data };
        case GET_EDU_LEVEL:
            return { ...state, educationLevels: data };
        case GET_EDUC_LEVEL:
            return { ...state, educationLevel: data };
        case GET_PRE_DEF_LANG:
            return { ...state, langs: data };
        case GET_FILTERED_LANGS:
            return { ...state, langs: data };
        case GET_JOB_TYPES:
            return { ...state, jobTypes: data };
        case CHECK_CANDIDATE_HAD_CANDIDATURES:
            return { ...state, isCandidateHadCandidatures: data };
        default:
            return state;
    }
};
export { ProfileReducer };
