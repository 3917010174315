// ** Logo
import logo_valjob_small from '@src/assets/images/logo/logo_valjob_small.png';

const SpinnerComponent = () => {
    return (
        <div className="fallback-spinner vh-100">
            <img className="fallback-logo" src={logo_valjob_small} alt="logo" />
            <div className="loading">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
            </div>
        </div>
    );
};

export default SpinnerComponent;
