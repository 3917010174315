// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import navbar from './reducers/navbar';
import layout from './reducers/layout';
import authReducer from './authentification/reducers';
import alertReducer from './alert/reducers';
import parsingReducer from './parsing/reducers';
import { ProfileReducer } from './profile/reducers';
import { MissionsReducer } from './missions/reducers';
import favoriteReducer from './favoris/reducers';
import savedSearchReducer from './savedSearch/reducers';
import candidatureReducer from './candidature/reducers';
import { CommunicateReducer } from './communication/reducers';
import { FaqReducer } from './support/reducers';
import timesheetReducer from './timesheet/reducers';
import { DashbordReducer } from './dashbord/reducers';
import { NotificationsReducer } from './notifications/reducers';
import { presentationFileReducer } from './presentationFile/reducers';
import myDocumentsReducer from './my-documents/reducers';
import agencyMissionsReducer from './agency-redux/cvTheque/reducers';
import adsReducer from './agency-redux/ads/reducers';
import applicationsReducer from './applications/reducers';
import missionReducer from './agency-redux/missions/reducers';
import myListFoldersreducer from './agency-redux/my-folders/reducers';
import ActivityReducer from './agency-redux/activity/reducers';
import dashbordAgencyReducer from './dashbordAgency/reducers';
import planningReducer from './calendar/reducers';
import { MailingReducer } from './mailing/reducers';
import exportReducer from './export/reducers';
import { sharedReducer } from './shared/reducers';
import announcementModalReducer from './modals/announcement/reducers';
import securityModalReducer from './modals/security/reducers';

const rootReducer = combineReducers({
    navbar,
    layout,
    auth: authReducer,
    alert: alertReducer,
    parsing: parsingReducer,
    profile: ProfileReducer,
    missions: MissionsReducer,
    favorite: favoriteReducer,
    savedSearch: savedSearchReducer,
    candidature: candidatureReducer,
    preferences: CommunicateReducer,
    support: FaqReducer,
    timesheet: timesheetReducer,
    dashbord: DashbordReducer,
    notif: NotificationsReducer,
    presentationFile: presentationFileReducer,
    myDocuments: myDocumentsReducer,
    agencyCvTheque: agencyMissionsReducer,
    ads: adsReducer,
    applications: applicationsReducer,
    missionsAgency: missionReducer,
    myListFolder: myListFoldersreducer,
    activity: ActivityReducer,
    agencyDashbord: dashbordAgencyReducer,
    planning: planningReducer,
    mail: MailingReducer,
    export: exportReducer,
    shared: sharedReducer,
    announcementModal: announcementModalReducer,
    securityModal: securityModalReducer,
});

export default rootReducer;
