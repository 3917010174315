const actions = {
    MY_LIST_FOLDERS: 'MY_LIST_FOLDERS',
    LIST_CANDIDATE: 'LIST_CANDIDATE',
    CANDIDATE_DETAILS: 'CANDIDATE_DETAILS',

    myListFilders: (data) => {
        return {
            type: actions.MY_LIST_FOLDERS,
            data,
        };
    },

    candidateList: (data) => {
        return {
            type: actions.LIST_CANDIDATE,
            data,
        };
    },

    candidateDetail: (data) => {
        return {
            type: actions.CANDIDATE_DETAILS,
            data,
        };
    },
};

export default actions;
