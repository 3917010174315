import actions from './actions';

const { LIST_FAVORITES, FAV_IDS, HANDLE_FAV } = actions;

const initialState = {
    listFavorite: [],
    favIds: [],
    handleFav: false,
};

const favoriteReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case LIST_FAVORITES:
            return {
                ...state,
                listFavorite: data,
            };
        case FAV_IDS:
            return {
                ...state,
                favIds: data,
            };
        case HANDLE_FAV:
            return {
                ...state,
                handleFav: data,
            };
        default:
            return state;
    }
};

export default favoriteReducer;
