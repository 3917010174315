import actions from './actions';

const {
    LIST_ACTIVITY,
    LIST_TYPE,
    LIST_GENRE,
    LIST_PARTICIPANT,
    LIST_MISSION,
    LIST_EXPORT,
    LIST_TEMPLATES,
    LIST_RESPONSES,
    POST_TEMPLATES,
} = actions;

const initialState = {
    activityData: null,
    type: null,
    genre: null,
    participant: null,
    mission: null,
    export: null,
    templates: null,
    responses: null,
    template: null,
};

const ActivityReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case POST_TEMPLATES:
            return { ...state, template: data };
        case LIST_RESPONSES:
            return { ...state, responses: data };
        case LIST_EXPORT:
            return { ...state, export: data };
        case LIST_GENRE:
            return { ...state, genre: data };
        case LIST_PARTICIPANT:
            return { ...state, participant: data };
        case LIST_MISSION:
            return { ...state, mission: data };
        case LIST_ACTIVITY:
            return { ...state, activityData: data };
        case LIST_TYPE:
            return { ...state, type: data };
        case LIST_TEMPLATES:
            return { ...state, templates: data };
        default:
            return state;
    }
};

export default ActivityReducer;
