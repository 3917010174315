const actions = {
    TOGGLE_MODAL: 'TOGGLE_MODAL',
    NEW_FOLDER: 'NEW_FOLDER',
    FILE_FOLDER: 'FILE_FOLDER',
    LIST_PRESENTATION_FOLDER: 'LIST_PRESENTATION_FOLDER',
    DETAIL_FOLDER: 'DETAIL_FOLDER',
    DETAIL_AGENCY: 'DETAIL_AGENCY',
    DELETE_FOLDER: 'DELETE_FOLDER',

    toggleModal: () => {
        return {
            type: actions.TOGGLE_MODAL,
        };
    },

    newFolder: (data) => {
        return {
            type: actions.NEW_FOLDER,
            data,
        };
    },

    listPresentationFolder: (data) => {
        return {
            type: actions.LIST_PRESENTATION_FOLDER,
            data,
        };
    },

    detailFolder: (data) => {
        return {
            type: actions.DETAIL_FOLDER,
            data,
        };
    },

    detailsAgency: (data) => {
        return {
            type: actions.DETAIL_AGENCY,
            data,
        };
    },
    deleteFolder: () => {
        return {
            type: actions.DELETE_FOLDER,
        };
    },
};

export default actions;
