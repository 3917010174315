const actions = {
    LIST_SEARCH: 'LIST_SEARCH',
    DELETE_SEARCH: 'DELETE_SEARCH',
    LIST_USER_SECTORS: 'LIST_USER_SECTORS',
    GET_ONE_SAVED_SEARCH: 'GET_ONE_SAVED_SEARCH',
    SAVE_SEARCH: 'SAVE_SEARCH',
    CLEAR_DATA: 'CLEAR_DATA',

    listSavedSearch: (data) => {
        return {
            type: actions.LIST_SEARCH,
            data,
        };
    },

    deleteSearch: (data) => {
        return {
            type: actions.DELETE_SEARCH,
            data,
        };
    },

    getListSectorUser: (data) => {
        return {
            type: actions.LIST_USER_SECTORS,
            data,
        };
    },

    getOneSavedSearch: (data) => {
        return {
            type: actions.GET_ONE_SAVED_SEARCH,
            data,
        };
    },

    saveNewSearch: (data) => {
        return {
            type: actions.SAVE_SEARCH,
            data,
        };
    },

    clearDataSavedSearch: (data) => {
        return {
            type: actions.CLEAR_DATA,
            data,
        };
    },
};

export default actions;
