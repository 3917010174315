const actions = {
    LIST_FAVORITES: 'LIST_FAVORITES',
    FAV_IDS: 'FAV_IDS',
    HANDLE_FAV: 'HANDLE_FAV',

    listFavorites: (data) => {
        return {
            type: actions.LIST_FAVORITES,
            data,
        };
    },

    handleFav: (data) => {
        return {
            type: actions.HANDLE_FAV,
            data,
        };
    },
};

export default actions;
