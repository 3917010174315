const actions = {
    LIST_AND_FILTER_FAQ: 'LIST_AND_FILTER_FAQ',
    FAQ_TOP_SEARCH: 'FAQ_TOP_SEARCH',
    SEND_MAIL_SUPPORT: 'SEND_MAIL_SUPPORT',
    GET_PARAMS: 'GET_PARAMS',

    listFaq: (data) => {
        return {
            type: actions.LIST_AND_FILTER_FAQ,
            data,
        };
    },

    topFaq: (data) => {
        return {
            type: actions.FAQ_TOP_SEARCH,
            data,
        };
    },

    sendMail: (data) => {
        return {
            type: actions.SEND_MAIL_SUPPORT,
            data,
        };
    },

    getParams: (data) => {
        return {
            type: actions.GET_PARAMS,
            data,
        };
    },
};

export default actions;
