const securityModalActions = {
    DISPLAY_MODAL: 'DISPLAY_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',

    displaySecurityModal: (data) => {
        return {
            type: securityModalActions.DISPLAY_MODAL,
            data: data,
        };
    },
    hideModal: (data) => {
        return {
            type: securityModalActions.HIDE_MODAL,
            data: data,
        };
    },
};

export default securityModalActions;
