const actions = {
    LIST_PREFERENCES: 'LIST_PREFERENCES',

    listPreferences: (data) => {
        return {
            type: actions.LIST_PREFERENCES,
            data,
        };
    },
};
export default actions;
