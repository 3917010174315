import actions from './actions';

const { LIST_AND_FILTER_FAQ, FAQ_TOP_SEARCH, SEND_MAIL_SUPPORT, GET_PARAMS } = actions;
const initialState = {
    listFaq: [],
    topFaq: [],
    sendMail: null,
    getParam: null,
};

const FaqReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case LIST_AND_FILTER_FAQ:
            return { ...state, listFaq: data };
        case FAQ_TOP_SEARCH:
            return { ...state, topFaq: data };
        case SEND_MAIL_SUPPORT:
            return { ...state, sendMail: data };
        case GET_PARAMS:
            return { ...state, getParam: data };
        default:
            return state;
    }
};

export { FaqReducer };
