const actions = {
    PARSING_CV: 'PARSING_CV',

    parsing: (data) => {
        return {
            type: actions.PARSING_CV,
            data,
        };
    },
};

export default actions;
