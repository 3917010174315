import actions from './actions';

const {
    GET_ONE_CLIENT,
    GET_DETAIL_PLANNING,
    GET_ClIENTS,
    GET_DETAIL_ORDER,
    GET_PLANNING_ORDER,
    GET_STATISTICS_ORDER,
    GET_CANDIDATES_ORDER,
    GET_STATISTICS_PLANNING,
    GET_MARKED_ORDERS,
} = actions;

const initialState = {
    detailOrder: null,
    planningOrder: null,
    statisticsOrder: null,
    candidatesOrder: null,
    statsPlanning: null,
    markedOrders: null,
    clients: null,
    planningData: null,
    oneClient: null,
};

const planningReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_DETAIL_ORDER:
            return { ...state, detailOrder: data };
        case GET_PLANNING_ORDER:
            return { ...state, planningOrder: data };
        case GET_STATISTICS_ORDER:
            return { ...state, statisticsOrder: data };
        case GET_CANDIDATES_ORDER:
            return { ...state, candidatesOrder: data };
        case GET_STATISTICS_PLANNING:
            return { ...state, statsPlanning: data };
        case GET_MARKED_ORDERS:
            return { ...state, markedOrders: data };
        case GET_ClIENTS:
            return { ...state, clients: data };
        case GET_DETAIL_PLANNING:
            return { ...state, planningData: data };
        case GET_ONE_CLIENT:
            return { ...state, oneClient: data };
        default:
            return state;
    }
};

export default planningReducer;
