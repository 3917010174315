const actions = {
    MY_DOCUMENTS_LIST: 'MY_DOCUMENTS_LIST',

    getDocumentsList: (data) => {
        return {
            type: actions.MY_DOCUMENTS_LIST,
            data,
        };
    },
};

export default actions;
