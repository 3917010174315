const actions = {
    SEND_MAIL: 'SEND_MAIL',

    sendMail: (data) => {
        return {
            type: actions.SEND_MAIL,
            data,
        };
    },
};
export default actions;
