import actions from './actions';

const { SEND_MAIL } = actions;

const initialState = {
    send: null,
};

const MailingReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case SEND_MAIL:
            return { ...state, send: data };
        default:
            return state;
    }
};
export { MailingReducer };
