import actions from './actions';

const { ALL_CANDIDATURE, ALL_PROPOSED_CANDIDATE, SET_CANDIDATURE_STATUS } = actions;

const initialState = {
    allCandidatures: null,
    allProposedCandidates: null,
};

const candidatureReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_CANDIDATURE:
            return { ...state, allCandidatures: data };
        case ALL_PROPOSED_CANDIDATE:
            return { ...state, allProposedCandidates: data };
        case SET_CANDIDATURE_STATUS:
            return state;
        default:
            return state;
    }
};

export default candidatureReducer;
