import actions from './actions';

const { PERCENTAGE, STATISTIC_DASHBORD, DELETE_ELEMENT_CADIDATURE_CANCELED, DELETE_ELEMENT_CADIDATURE_REFUSED } =
    actions;
const initialState = {
    percentProfile: null,
    statisticDashbord: null,
    elementDeleted: null,
    elementCanceled: null,
};

const DashbordReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case PERCENTAGE:
            return { ...state, percentProfile: data };
        case STATISTIC_DASHBORD:
            return { ...state, statisticDashbord: data };
        case DELETE_ELEMENT_CADIDATURE_CANCELED:
            return { ...state, elementDeleted: data };
        case DELETE_ELEMENT_CADIDATURE_REFUSED:
            return { ...state, elementCanceled: data };
        default:
            return state;
    }
};
export { DashbordReducer };
