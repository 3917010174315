import actionsAlert from './actions';

const { ALERT_SUCCESS, ALERT_ERROR, ALERT_HIDE, ALERT_WARNING } = actionsAlert;

const initialState = {
    msg: '',
    type: '',
    title: '',
    redirectLink: '',
    signature: '',
    jobTitle: '',
    actionButtons: [],
};

const alertReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ALERT_SUCCESS:
            return {
                ...state,
                msg: data.msg,
                title: data.title,
                type: data.type,
                redirectLink: data.redirectLink,
                signature: data.signature,
                jobTitle: data.jobTitle,
                actionButtons: data.actionButtons,
            };
        case ALERT_WARNING:
            return {
                ...state,
                msg: data.msg,
                title: data.title,
                type: data.type,
                redirectLink: data.redirectLink,
                signature: data.signature,
                jobTitle: data.jobTitle,
                actionButtons: data.actionButtons,
            };
        case ALERT_ERROR:
            return {
                ...state,
                msg: data.msg,
                title: data.title,
                type: data.type,
                redirectLink: data.redirectLink,
                actionButtons: data.actionButtons,
            };
        case ALERT_HIDE:
            return {
                ...state,
                msg: data.msg,
                title: data.title,
                type: data.type,
                redirectLink: data.redirectLink,
                actionButtons: data.actionButtons,
            };
        default:
            return state;
    }
};

export default alertReducer;
