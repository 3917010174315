const actions = {
    GET_DETAIL_ORDER: 'GET_DETAIL_ORDER',
    GET_PLANNING_ORDER: 'GET_PLANNING_ORDER',
    GET_STATISTICS_ORDER: 'GET_STATISTICS_ORDER',
    GET_CANDIDATES_ORDER: 'GET_CANDIDATES_ORDER',
    GET_STATISTICS_PLANNING: 'GET_STATISTICS_PLANNING',
    GET_MARKED_ORDERS: 'GET_MARKED_ORDERS',
    GET_ClIENTS: 'GET_ClIENTS',
    GET_DETAIL_PLANNING: 'GET_DETAIL_PLANNING',
    GET_ONE_CLIENT: 'GET_ONE_CLIENT',

    getDetailOrder: (data) => {
        return {
            type: actions.GET_DETAIL_ORDER,
            data,
        };
    },
    getPlanningOrder: (data) => {
        return {
            type: actions.GET_PLANNING_ORDER,
            data,
        };
    },
    getStatisticsOrder: (data) => {
        return {
            type: actions.GET_STATISTICS_ORDER,
            data,
        };
    },
    getCandidatesOrder: (data) => {
        return {
            type: actions.GET_CANDIDATES_ORDER,
            data,
        };
    },

    getStatsPlanning: (data) => {
        return {
            type: actions.GET_STATISTICS_PLANNING,
            data,
        };
    },

    getMarkedOrders: (data) => {
        return {
            type: actions.GET_MARKED_ORDERS,
            data,
        };
    },

    getClients: (data) => {
        return {
            type: actions.GET_ClIENTS,
            data,
        };
    },

    getDetailPlanning: (data) => {
        return {
            type: actions.GET_DETAIL_PLANNING,
            data,
        };
    },

    getOneClient: (data) => {
        return {
            type: actions.GET_ONE_CLIENT,
            data,
        };
    },
};

export default actions;
